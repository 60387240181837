import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { fonts } from 'folio-common-components';
import { colors } from 'folio-design-tokens';
import * as React from 'react';
import { NavLink, useMatch } from 'react-router-dom';
import { CheckForYesIcon } from '../../icons';
import { useValidationStatusByPage } from '../../utils/validators';
import type { MenuItem } from '../HeaderBar';

type NavLinkProps = {
  href: string;
  text: string;
  isValid: boolean;
};

interface Props {
  menuItems: readonly MenuItem[];
}

const circle = css`
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
`;

const linkActive = css`
  border-color: ${colors.blue};

  :hover {
    border-color: ${colors.blue};
  }
`;

const smallLink = css`
  &:not(:first-of-type) {
    margin-left: 2px;
  }
  text-decoration: none;
  border-radius: 50%;
  padding: 2px;
  border: 2px solid transparent;
  -webkit-tap-highlight-color: transparent;

  :hover {
    border-color: ${colors.blue};
  }

  :active {
    ${linkActive};
  }
`;

const SmallCircle = styled.span<{
  backgroundColor: string;
  color: string;
  isActive: boolean;
}>`
  ${circle};
  background: ${({ backgroundColor }) => backgroundColor};
  color: ${({ color }) => color};
`;

const CircleContent = styled.span<{
  color: string;
  backgroundColor: string;
  isActive: boolean;
}>`
  ${circle};
  background: ${({ backgroundColor }) => backgroundColor};
  color: ${({ color }) => color};
  margin-right: 12px;
`;

const largeLink = css`
  display: flex;
  align-items: center;
  padding: 4px;
  text-decoration: none;
  color: ${colors.black};
  margin-bottom: 16px;
  border-radius: 30px;
  ${fonts.font300medium};
  border: 2px solid transparent;
  position: relative;
  -webkit-tap-highlight-color: transparent;

  /* This makes sure there are no uncklickable areas between menu items, which looks
  better when moving the cursor between items since it avoids flashing the cursor. */
  ::before {
    content: '';
    position: absolute;
    /* 10 is: 16 (space between menu items) / 2 + 2 (border) */
    top: -10px;
    bottom: -10px;
    left: 0;
    right: 0;
  }

  :hover {
    border-color: ${colors.blueLightOpaque};
  }

  @media (prefers-contrast: more) {
    :hover {
      border-color: ${colors.blue};
    }
  }

  :active {
    ${linkActive};
  }
`;

const TopLevelMenuLinkSmall: React.FC<NavLinkProps & { index: number }> = ({
  href,
  text,
  index,
  isValid,
}) => {
  const isActive = useMatch(href) != null;

  return (
    <NavLink
      css={[smallLink, isActive && linkActive]}
      to={{ pathname: href, search: window.location.search }}
      title={text}
      aria-label={`Steg ${index}: ${text}`}
    >
      <SmallCircle
        isActive={isActive}
        backgroundColor={isValid ? colors.blue : colors.blueLightOpaque}
        color={isValid ? '#fff' : colors.blue}
      >
        {isValid ? <CheckForYesIcon /> : index}
      </SmallCircle>
    </NavLink>
  );
};

const TopLevelMenuLinkLarge: React.FC<NavLinkProps & { index: number }> = ({
  href,
  text,
  index,
  isValid,
}) => {
  const isActive = useMatch(href) != null;

  return (
    <NavLink
      css={[largeLink, isActive && linkActive]}
      to={{ pathname: href, search: window.location.search }}
      aria-label={`Steg ${index}: ${text}`}
    >
      <CircleContent
        isActive={isActive}
        backgroundColor={isValid ? colors.blue : colors.blueLightOpaque}
        color={isValid ? '#fff' : colors.blue}
      >
        {isValid ? <CheckForYesIcon /> : index}
      </CircleContent>{' '}
      {text}
    </NavLink>
  );
};

const TopLevelMenuSmall: React.FC<Props> = ({ menuItems, ...rest }) => {
  const status = useValidationStatusByPage();
  return (
    <nav
      aria-label="Hovednavigasjon"
      css={css`
        display: flex;
      `}
      {...rest}
    >
      {menuItems.map(({ href, text }, index) => (
        <TopLevelMenuLinkSmall
          key={href}
          href={href}
          text={text}
          index={index + 1}
          isValid={status.has(href)}
        />
      ))}
    </nav>
  );
};

const TopLevelMenuLarge: React.FC<Props> = ({ menuItems, ...rest }) => {
  const status = useValidationStatusByPage();
  return (
    <nav
      aria-label="Hovednavigasjon"
      css={css`
        padding: 0 1rem 0 calc(2rem - 6px);
      `}
      {...rest}
    >
      {menuItems.map(({ href, text }, index) => (
        <TopLevelMenuLinkLarge
          key={href}
          href={href}
          text={text}
          index={index + 1}
          isValid={status.has(href)}
        />
      ))}
    </nav>
  );
};

export { TopLevelMenuSmall, TopLevelMenuLarge };
