type FormElement = HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement;

declare global {
  interface Window {
    dataLayer?: any;
  }
}

export function track(
  category: string,
  action: string,
  label: string,
  value: number,
  nonInteraction: boolean,
) {
  if (window.dataLayer) {
    window.dataLayer.push({
      event: 'GAEvent',
      eventCategory: category,
      eventAction: action,
      eventLabel: label,
      eventValue: value,
      nonInteraction,
    });
  }
}

function getLabel(ele: FormElement) {
  const label =
    (ele.labels && ele.labels.length !== 0 && ele.labels[0].textContent) ||
    ele.getAttribute('aria-label') ||
    ele.name ||
    ele.id;
  return label;
}

export function setUpTracking() {
  document.addEventListener('change', event => {
    const target = event.target as HTMLElement;
    const tagName = target.tagName;
    if (tagName === 'INPUT' || tagName === 'TEXTAREA' || tagName === 'SELECT') {
      const formEle = target as FormElement;
      track(
        'form',
        'change',
        `${window.location.pathname.slice(1)} - ${getLabel(formEle)}`,
        5,
        false,
      );
    }
  });
}

export function trackLinkedinEvent(conversationId: string): void {
  const image = new Image();
  image.src = `https://px.ads.linkedin.com/collect/?pid=478884&conversionId=${conversationId}&fmt=gif`;
}
