import type * as React from 'react';
export const PhoneIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={22}
    fill="none"
    viewBox="0 0 22 22"
    aria-hidden="true"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M14.2236 19.966c.7151.1576 1.3078.3409 1.9585.1576.6507-.1833 1.4115-.8172 1.7856-1.3802l.6983-.8474c.6869-.8335.588-2.0617-.2235-2.7745l-2.1982-1.931a1 1 0 0 0-1.3934.0715l-1.2967 1.399c-3.1741-1.3602-4.5356-2.6751-5.988-5.9974L9.032 7.1781a1 1 0 0 0 .0198-1.384L7.1259 3.7275c-.7535-.8086-2.0202-.8526-2.828-.0983l-.7437.6944c-1.0616.9991-1.28 1.7055-1.3882 2.275-.108.5694 0 1.6083.2955 2.6269 2.2297 5.9974 6.2132 9.5178 11.7621 10.7404Z"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M16.3298 8.6993a1.9399 1.9399 0 0 0 .1307-.7023c0-1.0741-.8708-1.9448-1.9449-1.9448a1.94 1.94 0 0 0-.7023.1306M20.0009 9.4931a5.628 5.628 0 0 0 .3792-2.0377c0-3.1165-2.5265-5.643-5.643-5.643a5.6288 5.6288 0 0 0-2.0377.3792"
    />
  </svg>
);
