import { css } from '@emotion/react';
import { colors } from 'folio-design-tokens';

// TODO: move this to global style when everything is migrated
export const defaultLink = css`
  --link-color: ${colors.blue};
  --link-hover-color: ${colors.blueShadow};
  --link-underline-color: ${colors.blue50};
  --link-underline-hover-color: ${colors.blueShadow};
  color: var(--link-color);
  border-radius: 4px; /* To look better when focused */
  box-decoration-break: clone;
  text-decoration: underline;
  text-decoration-color: var(--link-underline-color);
  text-decoration-skip-ink: none;
  text-decoration-thickness: 2px;
  text-underline-offset: 5px;
  transition: text-decoration-color 0.2s, color 0.2s;
  font-weight: 500;

  @media (hover) {
    :hover {
      --link-color: var(--link-hover-color);
      --link-underline-color: var(--link-underline-hover-color);
    }
  }
`;

export const blackLink = css`
  --link-color: ${colors.black};
  --link-hover-color: ${colors.blue};
  --link-underline-color: ${colors.grayShadow};
  --link-underline-hover-color: ${colors.blue};
  font-weight: inherit;

  @media (prefers-contrast: more) {
    --link-underline-color: ${colors.black};
  }
`;

export const grayLink = css`
  --link-color: ${colors.wcagGray};
  --link-hover-color: ${colors.black};
  --link-underline-color: ${colors.grayShadow};
  --link-underline-hover-color: ${colors.black};
  font-weight: inherit;

  @media (prefers-contrast: more) {
    ${blackLink};
  }
`;
