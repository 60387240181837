import { uniqueBy, validators } from 'folio-common-utils';
import * as uuid from 'uuid';
import * as services from '../../services';
import { settings } from '../../settings';
import { actions as appActions } from '../app';
import type { Dispatch, GetState } from '../types';
import {
  type EditorReducerState,
  type Person,
  actions as editorActions,
  selectors as editorSelectors,
  newCompany,
  newPerson,
} from './';

export function stateToWireFormat(state: EditorReducerState) {
  return JSON.stringify(state);
}

export function saveSnapshot() {
  return async function (dispatch: Dispatch, getState: GetState) {
    const state = getState();
    if (state.app.saving) {
      // eslint-disable-next-line no-console
      console.log("Can't save while saving in progress");
      setTimeout(() => dispatch(appActions.setSavingIdle()), 5000);
      return;
    }
    if (state.editor.dirty) {
      dispatch(appActions.setSavingActive());
      if (state.app.pristine) {
        dispatch(appActions.setPristine(false));
      }
      dispatch(editorActions.setDirty(false));
      if (!state.editor.readOnly) {
        await services.saveEditorState({
          id: state.editor.id,
          body: stateToWireFormat(state.editor),
        });
      }
      dispatch(appActions.setSavingIdle());
    }
  };
}

type PersonWithRolesAndOwnership = ReturnType<
  typeof editorSelectors.peopleWithRolesAndOwnership
>[number];

export function updateRolePerson(inPerson: PersonWithRolesAndOwnership): any {
  return function (dispatch: Dispatch, getState: GetState) {
    const state = getState();
    const extantPerson = editorSelectors.getPersonByIdOrPnum(state, inPerson);

    const person = {
      id: extantPerson?.id ?? inPerson.id,
      name: inPerson.name,
      pNum: inPerson.pNum,
      phone: inPerson.phone,
      email: inPerson.email,
    };

    dispatch(editorActions.setPersonInfo(person));

    inPerson.roles.forEach(role => {
      dispatch(editorActions.assignRole({ id: person.id, role }));
    });
  };
}

export type PersonFounder = Partial<Person> & { id: string };

export function addPersonFounder(inPerson: PersonFounder) {
  return function (dispatch: Dispatch, getState: GetState) {
    const state = getState();
    const extantPerson = editorSelectors.getPersonByIdOrPnum(state, inPerson);

    let person;
    if (extantPerson === undefined) {
      person = Object.assign(newPerson(), inPerson);
    } else {
      person = Object.assign({}, extantPerson, inPerson, {
        id: extantPerson.id,
      });
    }

    dispatch(editorActions.setPersonInfo(person));
    const shouldBeBoardMember = Object.keys(state.editor.people).length === 0;
    dispatch(editorActions.setEquity({ id: person.id, equity: '' }));
    if (shouldBeBoardMember) {
      dispatch(
        editorActions.assignRole({ id: person.id, role: 'boardMember' }),
      );
    }
    if (!state.editor.equityAssignmentDirty) {
      dispatch(editorActions.balanceEquity());
    }
    dispatch(autoAssignBoard() as any);
  };
}

export interface CompanyFounder {
  name: string;
  orgId: string;
}

export interface CompanySignatory {
  id: string;
  name: string;
  pNum: string;
  phone: string;
}

export function addCompanyFounder(
  inCompany: CompanyFounder,
  signatory: CompanySignatory,
): any {
  return function (dispatch: Dispatch, getState: GetState) {
    const state = getState();
    const extantPerson = editorSelectors.getPersonByPnum(state, signatory.pNum);
    const id = extantPerson ? extantPerson.id : signatory.id || uuid.v1();
    dispatch(editorActions.setPersonInfo({ ...signatory, id }));
    const company = Object.assign(newCompany(), inCompany, {
      signatories: [id],
    });
    dispatch(editorActions.setCompanyInfo(company));
    dispatch(editorActions.setEquity({ id: company.id, equity: '' }));
    if (!state.editor.equityAssignmentDirty) {
      dispatch(editorActions.balanceEquity());
    }
    dispatch(autoAssignBoard() as any);
  };
}

export function editCompanyFounder(
  prevOrgId: string,
  inCompany: CompanyFounder,
  signatory: CompanySignatory,
) {
  return function (dispatch: Dispatch, getState: GetState) {
    const state = getState();
    const owner = editorSelectors.getCompanyOwner(state, prevOrgId);
    if (owner) {
      dispatch(removeOwnership(owner.item.id) as any);
      dispatch(addCompanyFounder(inCompany, signatory));
    }
  };
}

interface EditPerson {
  id: string;
  name: string;
  pNum: string;
  phone: string;
  email: string;
}

// fixme: temporary thunk to deal with adding a new person as the CEO.
// Refactor this later
export function addPersonCeo(inPerson: EditPerson): any {
  return function (dispatch: Dispatch, getState: GetState) {
    const state = getState();
    const extantPerson = editorSelectors.getPersonByIdOrPnum(state, inPerson);

    const person = {
      id: extantPerson?.id ?? inPerson.id,
      name: inPerson.name,
      pNum: inPerson.pNum,
      phone: inPerson.phone,
      email: inPerson.email,
    };

    dispatch(editorActions.setPersonInfo(person));
    dispatch(editorActions.assignCeo(person.id));
  };
}

export function toggleOwnershipType() {
  return (dispatch: Dispatch, getState: GetState) => {
    const state = getState();
    if (state.editor.ownershipType === 'single') {
      dispatch(editorActions.setOwnershipType('multiple'));
    } else if (state.editor.ownershipType === 'multiple') {
      dispatch(editorActions.setOwnershipType('single'));
    }
  };
}

export function removeOwnership(id: string) {
  return (dispatch: Dispatch, getState: GetState) => {
    const state = getState();
    dispatch(editorActions.removeAsOwner(id));
    if (!state.editor.equityAssignmentDirty) {
      dispatch(editorActions.balanceEquity());
    }
    dispatch(autoAssignBoard() as any);
  };
}

export function assignEquityAndMakeDirty(assignment: {
  id: string;
  equity: string;
}) {
  return (dispatch: Dispatch, getState: GetState) => {
    const state = getState();
    const { id, equity } = assignment;
    dispatch(editorActions.setEquity({ id, equity }));
    if (!state.editor.equityAssignmentDirty) {
      dispatch(editorActions.setEquityDirty(true));
    }
  };
}

export function autoAssignBoard() {
  return (dispatch: Dispatch, getState: GetState) => {
    const state = getState();
    if (state.editor.hasEditableBoard) {
      return;
    }

    const capital = Number(state.editor.coreInfo.capital) || 0;

    const boardMemberThreshold =
      settings.ownershipBoardMemberThreshold === 0
        ? 1
        : capital / settings.ownershipBoardMemberThreshold;
    const owners = uniqueBy(
      e => e.id,
      editorSelectors
        .owners(state)
        .filter(e => e.equity >= boardMemberThreshold)
        .sort((a, b) => b.equity - a.equity)
        .map(e => (e.kind === 'person' ? e.item : e.signatories[0])),
    );

    dispatch(editorActions.clearRoles());
    // Fall back to form owner in case all owners are deleted
    const chairman = owners[0] || state.editor.people[state.editor.formOwner];
    if (chairman) {
      dispatch(editorActions.assignRole({ id: chairman.id, role: 'chair' }));
      owners.slice(1).forEach(e => {
        dispatch(editorActions.assignRole({ id: e.id, role: 'boardMember' }));
      });
    }
  };
}

export function submitEmail() {
  return async (dispatch: Dispatch, getState: GetState) => {
    const state = getState();
    if (state.editor.hasSubmittedEmail) {
      return;
    }

    const person = state.editor.people[state.editor.formOwner];
    if (validators.isValidEmail(person.email) && person.name !== '') {
      const response = await services.submitEmail({
        id: state.editor.id,
        name: person.name,
        email: person.email,
        phone: person.phone,
      });
      dispatch(editorActions.setEmailSubmitted(response));
    }
  };
}
