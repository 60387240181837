import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { colors } from 'folio-design-tokens';
import * as React from 'react';
import { font200book, font300book } from '../fonts';
import { getSpacing } from '../spacing';
import { baseButtonStyle } from './sharedStyles';

type Level = 'primary' | 'destructive';
type Size = 'large' | 'small';

function getColor(level: Level, levelToColor: Record<Level, string>) {
  return levelToColor[level];
}

const dynamicStyles = ({
  level,
  size,
  hasIcon,
}: {
  level: Level;
  size: Size;
  hasIcon: boolean;
}) => css`
  ${size === 'small' ? font200book : font300book};

  &:active {
    color: ${getColor(level, {
      primary: colors.black,
      destructive: colors.wcagRed,
    })};
  }

  &::before {
    content: '';
    pointer-events: none;
    position: absolute;
    transition: opacity 0.2s;
    top: -2px;
    right: -4px;
    bottom: -2px;
    left: -4px;
    border-radius: 8px;
    opacity: 0;
    background-color: ${getColor(level, {
      primary: colors.grayLight,
      destructive: colors.redLight,
    })};
  }

  &:active::before {
    opacity: 1;
  }

  &::after {
    content: '';
    position: absolute;
    transition: opacity 0.2s;
    right: -1px;
    bottom: -1px;
    left: -1px;
    border-bottom: 2px solid ${colors.grayMedium};
    opacity: ${hasIcon ? 0 : 1};

    @media (prefers-contrast: more) {
      border-color: ${colors.black};
    }
  }

  &:active::after {
    opacity: 0;
  }

  @media (hover) {
    &:hover {
      color: ${getColor(level, {
        primary: colors.black,
        destructive: colors.wcagRed,
      })};
    }

    &:hover::before {
      opacity: 1;
    }

    &:hover::after {
      opacity: 0;
    }
  }
`;

const StyledButton = styled.button`
  ${baseButtonStyle};
  display: inline-block;
  border-radius: 8px;
  position: relative;
  font-weight: 400;
  -webkit-tap-highlight-color: transparent;
  ${dynamicStyles};

  &.focus-visible {
    box-shadow: none;
  }

  &.focus-visible::before {
    opacity: 1;
    box-shadow: inset 0 0 0 2px ${colors.blue};
    border-radius: 8px;
  }
`;

export interface ButtonProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  level?: Level;
  size?: Size;
  icon?: React.ReactElement;
}

const BaseTextButton: React.ForwardRefRenderFunction<
  HTMLButtonElement,
  ButtonProps
> = (props, ref) => {
  const { level = 'primary', size = 'small', icon, children, ...rest } = props;

  return (
    <StyledButton
      level={level}
      size={size}
      hasIcon={icon !== undefined}
      ref={ref}
      type="button"
      {...rest}
    >
      {icon && (
        <span
          css={css`
            display: inline-block;
            height: 22px;
            ${children ? getSpacing([8], 'margin-right') : null};

            > * {
              vertical-align: middle;
            }
          `}
        >
          {icon}
        </span>
      )}
      {children}
    </StyledButton>
  );
};

export const TextButton = React.forwardRef(BaseTextButton);

TextButton.displayName = 'TextButton';
