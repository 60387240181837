import isPropValid from '@emotion/is-prop-valid';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { colors } from 'folio-design-tokens';
import * as React from 'react';
import { ProgressSpinner } from '../Spinner';
import {
  font100book,
  font100medium,
  font200book,
  font200medium,
  font300book,
  font300medium,
} from '../fonts';
import { getSpacing } from '../spacing';
import { baseButtonStyle } from './sharedStyles';

type Level = 'primary' | 'secondary' | 'destructive';
type Size = 'large' | 'medium' | 'small';
type FullWidth = boolean;

function getPadding(size: Size) {
  switch (size) {
    case 'large':
      return css`
        padding: 20px 24px;

        &:not(:disabled):active {
          padding-bottom: 19px;
        }
      `;

    case 'medium':
      return css`
        padding: 13px 16px;

        &:not(:disabled):active {
          padding-bottom: 12px;
        }
      `;

    case 'small':
      return css`
        padding: 6px 12px;

        &:not(:disabled):active {
          padding-bottom: 5px;
        }
      `;
  }
}

function getFont(size: Size, level: Level) {
  switch (size) {
    case 'large':
      return level === 'primary' ? font300medium : font300book;

    case 'medium':
      return level === 'primary' ? font200medium : font200book;

    case 'small':
      return level === 'primary' ? font100medium : font100book;
  }
}

function getColor(level: Level, levelToColor: Record<Level, string>) {
  return levelToColor[level];
}

const dynamicStyles = ({
  level,
  size,
  fullWidth,
}: {
  level: Level;
  size: Size;
  fullWidth?: FullWidth;
}) => css`
  ${getPadding(size)};
  ${getFont(size, level)};
  width: ${fullWidth ? '100%' : null};
  color: ${getColor(level, {
    primary: '#fff',
    secondary: colors.black,
    destructive: colors.black,
  })};
  transition: background 0.2s;
  background-color: ${getColor(level, {
    primary: colors.blue,
    secondary: '#fff',
    destructive: '#fff',
  })};
  --secondary-box-shadow-color: ${colors.grayShadowOpaque};
  @media (prefers-contrast: more) {
    --secondary-box-shadow-color: ${colors.black};
  }
  box-shadow: inset 0 0 0 1px
      ${getColor(level, {
        primary: 'transparent',
        secondary: 'var(--secondary-box-shadow-color)',
        destructive: colors.red,
      })},
    inset 0 ${size === 'large' ? '-5px' : '-3px'}
      ${getColor(level, {
        primary: colors.blueShadow,
        secondary: 'var(--secondary-box-shadow-color)',
        destructive: colors.red,
      })};

  @media (hover) {
    &:hover {
      background-color: ${getColor(level, {
        primary: colors.blueHover,
        secondary: colors.grayAiryOpaque,
        destructive: colors.redHover,
      })};
      box-shadow: inset 0 0 0 1px
          ${getColor(level, {
            primary: 'transparent',
            secondary: 'var(--secondary-box-shadow-color)',
            destructive: colors.red,
          })},
        inset 0 ${size === 'large' ? '-5px' : '-3px'}
          ${getColor(level, {
            primary: colors.blueShadow,
            secondary: 'var(--secondary-box-shadow-color)',
            destructive: colors.red,
          })};
    }
  }

  &:active {
    background-color: ${getColor(level, {
      primary: colors.blueHover,
      secondary: colors.grayAiryOpaque,
      destructive: colors.redHover,
    })};
    box-shadow: inset 0 0 0 1px
        ${getColor(level, {
          primary: 'transparent',
          secondary: 'var(--secondary-box-shadow-color)',
          destructive: colors.red,
        })},
      inset 0 ${size === 'large' ? '-4px' : '-2px'}
        ${getColor(level, {
          primary: colors.blueShadow,
          secondary: 'var(--secondary-box-shadow-color)',
          destructive: colors.red,
        })};
  }

  &.focus-visible {
    box-shadow: inset 0 0 0 1px
        ${getColor(level, {
          primary: 'transparent',
          secondary: 'var(--secondary-box-shadow-color)',
          destructive: colors.red,
        })},
      inset 0 ${size === 'large' ? '-5px' : '-3px'}
        ${getColor(level, {
          primary: colors.blueShadow,
          secondary: 'var(--secondary-box-shadow-color)',
          destructive: colors.red,
        })};
  }
`;

const StyledButton = styled('button', {
  shouldForwardProp: prop => isPropValid(prop) && prop !== 'size',
})`
  ${baseButtonStyle};
  position: relative;
  display: inline-block;
  border-radius: 8px;
  -webkit-tap-highlight-color: transparent;
  ${dynamicStyles};

  /* use :not(:disabled) instead of :enabled to work for dummy buttons */
  &:not(:disabled):active {
    margin-bottom: 1px;
    transform: translateY(1px);
  }

  &:focus {
    outline: none;
  }

  &.focus-visible::after {
    content: '';
    position: absolute;
    top: -4px;
    right: -4px;
    bottom: -4px;
    left: -4px;
    border: 2px solid ${colors.blue};
    border-radius: 12px;
    pointer-events: none;
  }

  &:disabled {
    background: ${colors.grayMedium};
    box-shadow: inset 0 0 0 1px ${colors.grayShadowOpaque},
      inset 0 -4px ${colors.grayShadowOpaque};
    color: ${colors.wcagGray};
    cursor: default;
  }
`;

const StyledDummyButton = StyledButton.withComponent('span');

interface ContentProps {
  /** Importance level of the button */
  level?: Level;
  /** The size of the button */
  size?: Size;
  /** Display a progress spinner instead of the button body */
  loading?: boolean;
  icon?: React.ReactElement;
  iconRight?: boolean;
}

interface ContentPropsAndFullWidth extends ContentProps {
  fullWidth?: FullWidth;
}

export type ButtonProps = JSX.IntrinsicElements['button'] &
  ContentPropsAndFullWidth;

export type DummyButtonProps = JSX.IntrinsicElements['span'] &
  ContentPropsAndFullWidth;

export type ButtonLinkProps = Omit<ContentPropsAndFullWidth, 'loading'>;

const BaseButton: React.ForwardRefRenderFunction<
  HTMLButtonElement,
  ButtonProps
> = (props, ref) => {
  const {
    level = 'primary',
    size = 'medium',
    type = 'button',
    fullWidth,
    children,
    loading,
    icon,
    iconRight,
    onClick,
    ...rest
  } = props;

  return (
    <StyledButton
      level={level}
      size={size}
      fullWidth={fullWidth}
      type={type}
      ref={ref}
      onClick={event => {
        if (!loading && onClick) {
          onClick(event);
        }
      }}
      {...rest}
    >
      <Content size={size} loading={loading} icon={icon} iconRight={iconRight}>
        {children}
      </Content>
    </StyledButton>
  );
};

const BaseDummyButton: React.ForwardRefRenderFunction<
  HTMLSpanElement,
  Omit<DummyButtonProps, 'disabled' | 'type'>
> = (props, ref) => {
  const {
    level = 'primary',
    size = 'medium',
    fullWidth,
    children,
    loading,
    icon,
    iconRight,
    ...rest
  } = props;

  return (
    <StyledDummyButton
      level={level}
      size={size}
      fullWidth={fullWidth}
      ref={ref}
      {...rest}
    >
      <Content size={size} loading={loading} icon={icon} iconRight={iconRight}>
        {children}
      </Content>
    </StyledDummyButton>
  );
};

const BaseButtonLink: React.ForwardRefRenderFunction<
  HTMLAnchorElement,
  Pick<
    JSX.IntrinsicElements['a'],
    'href' | 'className' | 'target' | 'onClick'
  > &
    ButtonLinkProps & { children: React.ReactNode }
> = ({ fullWidth, size, level, icon, iconRight, children, ...rest }, ref) => (
  <a
    css={css`
      display: inline-block;
      border-radius: 8px;
      position: relative;
      -webkit-tap-highlight-color: transparent;
      width: ${fullWidth ? '100%' : null};

      &.focus-visible {
        box-shadow: none;
      }

      &.focus-visible::after {
        content: '';
        position: absolute;
        top: -4px;
        right: -4px;
        bottom: -4px;
        left: -4px;
        border: 2px solid ${colors.blue};
        border-radius: 10px;
        pointer-events: none;
      }
    `}
    ref={ref}
    {...rest}
  >
    <DummyButton
      fullWidth={fullWidth}
      size={size}
      level={level}
      icon={icon}
      iconRight={iconRight}
    >
      {children}
    </DummyButton>
  </a>
);

export const Content: React.FC<
  React.PropsWithChildren<ContentProps>
> = props => {
  const { children, size, loading, icon, iconRight } = props;
  return (
    <>
      <span
        css={css`
          position: relative;
          top: ${size === 'large'
            ? '-2px'
            : size === 'medium'
            ? '-1px'
            : undefined};
          visibility: ${loading ? 'hidden' : null};
          display: flex;
          flex-direction: ${iconRight ? 'row-reverse' : null};
          justify-content: center;
        `}
      >
        {icon && (
          <span
            css={css`
              height: 22px;
              ${getSpacing([8], iconRight ? 'margin-left' : 'margin-right')};

              > * {
                vertical-align: middle;
              }
            `}
          >
            {icon}
          </span>
        )}
        {children}
      </span>
      {loading && (
        <span
          css={css`
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            display: flex;
            align-items: center;
            justify-content: center;
          `}
        >
          <ProgressSpinner
            size={size === 'small' ? 16 : undefined}
            color="currentColor"
          />
        </span>
      )}
    </>
  );
};

/**
 * Button with standard Folio styling and onClick handling.
 */
export const Button = React.forwardRef(BaseButton);

/**
 * A `span` element with the same look and feel as a button. Useful when there
 * are parent elements of the `DummyButton` that handles interaction.
 */
export const DummyButton = React.forwardRef(BaseDummyButton);

/**
 * An `a` element with the same look and feel as a button.
 */
export const ButtonLink = React.forwardRef(BaseButtonLink);
