import * as React from 'react';
import { Label } from '../Label';
import {
  type Size,
  type TextfieldProps,
  textLikeInputError,
  useTextfieldMessage,
} from '../text-field';
import { TextInputContainer } from './TextInputContainer';

export interface Props
  extends TextfieldProps,
    Omit<
      React.InputHTMLAttributes<HTMLInputElement>,
      'value' | 'onChange' | 'size'
    > {
  inputRef?: React.RefObject<HTMLInputElement>;
  icon?: React.ReactNode;
  size?: Size;
  rightContent?: {
    content: React.ReactNode;
    width: number;
  };
}

export { TextInputContainer } from './TextInputContainer';

/**
 * A controlled text input component. Supports label, icons, messages, errors
 * and different sizes.
 */
export const TextInput: React.FC<Props> = props => {
  const {
    children,
    label,
    inputRef,
    onChange,
    type = 'text',
    spellCheck = false,
    ...rest
  } = props;
  const handleChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      onChange(value);
    },
    [onChange],
  );

  const { hasError, inputProps, messageEle } = useTextfieldMessage(props);

  let id = React.useId();
  if (rest.id) {
    id = rest.id;
  }

  return (
    <>
      {label ? <Label htmlFor={id}>{label}</Label> : null}
      <TextInputContainer
        {...rest}
        id={id}
        type={type}
        spellCheck={spellCheck}
        ref={inputRef}
        css={hasError && textLikeInputError}
        onChange={handleChange}
        {...inputProps}
      />
      {children}
      {messageEle}
    </>
  );
};
