import styled from '@emotion/styled';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { DummyButton } from '../buttons';

interface Props {
  to: string;
  style?: React.CSSProperties;
  onClick?: () => void;
}

const StyledLink = styled(Link)`
  text-decoration: none;
  display: inline-block;
  width: 100%;
`;

export const ContinueLink: React.FC<React.PropsWithChildren<Props>> = props => (
  <StyledLink
    to={{
      pathname: props.to,
      search: window.location.search,
    }}
    onClick={props.onClick}
  >
    <DummyButton size="large" fullWidth={true}>
      {props.children}
    </DummyButton>
  </StyledLink>
);
