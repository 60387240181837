import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

export const HeaderBar = styled.header`
  overflow: hidden;

  @media (max-width: 880px) {
    margin-bottom: 2rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding: 1rem 0;
  }

  @media not all and (min-width: 500px) {
    padding: 0;
  }
`;

export const StyledLink = styled(Link)`
  color: inherit;
  text-decoration: none;
  align-items: center;
  display: flex;
  font-size: 22px;
  font-weight: normal;
`;
