// eslint-disable-next-line @typescript-eslint/triple-slash-reference
/// <reference path="../../../node_modules/@types/webpack-env/index.d.ts" />
/// <reference types="@emotion/react/types/css-prop" />

// eslint-disable-next-line import/no-unassigned-import
import 'focus-visible';
import { configureStore } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import { invariant } from 'folio-common-utils';
import { setupSentry } from 'folio-sentry-web';
import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { App } from './App';
import { rootReducer } from './state';
import { setUpTracking } from './utils/track';
// import { registerServiceWorker } from './registerServiceWorker';

const store = configureStore({ reducer: rootReducer });

class ErrorBoundary extends React.Component<React.PropsWithChildren> {
  componentDidCatch(error, info) {
    Sentry.captureException({ error, info });

    // TODO: Should print an actual error message
    throw error;
  }

  render() {
    return this.props.children;
  }
}

setUpTracking();

setupSentry('https://869af7e6187a49bd995ebb7baaa4718c@sentry.folio.no/8');

const rootElement = document.getElementById('root');
invariant(rootElement, 'Could not find <div id="root"></div>');

const root = createRoot(rootElement);
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <React.StrictMode>
        <ErrorBoundary>
          <App />
        </ErrorBoundary>
      </React.StrictMode>
    </BrowserRouter>
  </Provider>,
);

if (module.hot) {
  module.hot.accept('./App', () => {
    root.render(
      <Provider store={store}>
        <BrowserRouter>
          <ErrorBoundary>
            <App />
          </ErrorBoundary>
        </BrowserRouter>
      </Provider>,
    );
  });
}

// registerServiceWorker();
