import { invariant } from 'folio-common-utils';
import type { RootState } from '../';
import type { EditorReducerState } from './types';
import type { Company, Person, Role } from './';

/**
 * Returns a list of all people with share count.
 */
export type PeopleSelectorResult = (Person & { shares: number })[];

export function getCeo(state: RootState): Person | undefined {
  const { ceo, people } = state.editor;
  // fixme: can there ever not be a ceo?
  if (ceo) {
    return people[ceo];
  } else {
    return undefined;
  }
}

export function getChair(state: RootState): Person {
  const { roles, people } = state.editor;
  const chair = roles.find(assignment => assignment.role === 'chair');

  invariant(chair, 'There must be a chair');

  return people[chair.id];
}

export function getBoardMember(state: RootState): Person[] {
  const { roles, people } = state.editor;
  return roles
    .filter(assignment => assignment.role === 'boardMember')
    .map(assignment => people[assignment.id]);
}

export function getDeputy(state: RootState): Person[] {
  const { roles, people } = state.editor;
  return roles
    .filter(assignment => assignment.role === 'deputy')
    .map(assignment => people[assignment.id]);
}

export function getAllBoardMembers(state: RootState): Person[] {
  return [getChair(state), ...getBoardMember(state), ...getDeputy(state)];
}

type PersonWithRolesAndOwnership = Person & {
  equity: number;
  isCeo: boolean;
  roles: Role[];
};

export function equityByOwners(editor: EditorReducerState) {
  if (editor.ownershipType === 'single') {
    return { [editor.formOwner]: editor.coreInfo.capital };
  } else {
    return editor.equity;
  }
}

export function peopleWithRolesAndOwnership(
  state: RootState,
): PersonWithRolesAndOwnership[] {
  const { editor } = state;

  const people = Object.values(editor.people).map(person => {
    const equity = Number(editor.equity[person.id]) || 0;
    const isCeo = person.id === editor.ceo;
    const roles = editor.roles
      .filter(role => person.id === role.id)
      .map(role => role.role);

    const ret: PersonWithRolesAndOwnership = Object.assign({}, person, {
      equity,
      isCeo,
      roles,
    });
    return ret;
  });
  return people;
}

interface BaseOwner {
  equity: number;
  equityAsString: string;
  capital: number;
  fraction: number;
}

export interface CompanyOwner extends BaseOwner {
  kind: 'company';
  item: Company;
  signatories: Person[];
}

export interface PersonOwner extends BaseOwner {
  kind: 'person';
  item: Person;
}

export type Owner = CompanyOwner | PersonOwner;

export function getPeopleOwners(state: RootState): Person[] {
  const peopleOwners: Person[] = [];
  for (const [id, person] of Object.entries(state.editor.people)) {
    if (id in state.editor.equity) {
      peopleOwners.push(person);
    }
  }
  return peopleOwners;
}

export function getSignatories(state: RootState): Person[] {
  const signatories = new Set<Person>();
  for (const company of Object.values(state.editor.companies)) {
    for (const signatory of company.signatories) {
      signatories.add(state.editor.people[signatory]);
    }
  }
  return [...signatories];
}

export function owners(state: RootState): Owner[] {
  const equityMap = equityByOwners(state.editor);

  const idsWithEquity = Object.entries(equityMap)
    .filter(([_key, value]) => typeof value === 'string')
    .map(([key]) => key);

  const shareCount = state.editor.coreInfo.shareCount;
  const totalShares = shareCount === '' ? 0 : Number(shareCount);

  if (state.editor.ownershipType === 'single') {
    const id = idsWithEquity[0];
    const person = state.editor.people[id];

    const equity = Number(equityMap[person.id]) || 0;
    const capital = equity;

    const owner: PersonOwner = {
      kind: 'person',
      item: person,
      equity,
      equityAsString: equityMap[person.id],
      fraction: 1,
      capital,
    };

    return [owner];
  }

  return idsWithEquity.map(id => {
    const equity = Number(state.editor.equity[id]) || 0;
    const fraction = totalShares === 0 ? 0 : equity / totalShares;
    const capital = Number(state.editor.coreInfo.capital) || 0;

    if (state.editor.people[id] !== undefined) {
      const e: PersonOwner = {
        kind: 'person',
        item: state.editor.people[id],
        equity,
        equityAsString: state.editor.equity[id],
        fraction,
        capital,
      };
      return e;
    } else {
      const e: CompanyOwner = {
        kind: 'company',
        item: state.editor.companies[id],
        equity,
        equityAsString: state.editor.equity[id],
        fraction,
        capital,
        signatories: state.editor.companies[id].signatories.map(
          e => state.editor.people[e],
        ),
      };
      return e;
    }
  });
}

export function getPersonByPnum(state: RootState, pNum: string) {
  if (pNum === '') {
    return undefined;
  }
  return Object.values(state.editor.people).find(e => e.pNum === pNum);
}

export function getPersonByIdOrPnum(
  state: RootState,
  p: { id: string; pNum?: string },
): Person | undefined {
  const item =
    p.pNum === undefined ? undefined : getPersonByPnum(state, p.pNum);
  if (item) {
    return item;
  } else {
    return state.editor.people[p.id];
  }
}

function isCompanyOwner(owner: Owner): owner is CompanyOwner {
  return owner.kind === 'company';
}

export function getCompanyOwner(state: RootState, orgId: string | undefined) {
  if (orgId === undefined) {
    return undefined;
  } else {
    return owners(state)
      .filter(isCompanyOwner)
      .find(e => e.kind === 'company' && e.item.orgId === orgId);
  }
}
export function userCanOnboard(state: RootState, personId: string) {
  return personId === getChair(state).id || personId === getCeo(state)?.id;
}
