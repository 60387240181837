import { Global, css } from '@emotion/react';
import { colors } from 'folio-design-tokens';
import * as React from 'react';
/* eslint-disable import/no-internal-modules, @typescript-eslint/ban-ts-comment */
// @ts-expect-error
import blackWoff2 from '../fonts/MaisonNeue-Black.woff2';
// @ts-expect-error
import boldWoff2 from '../fonts/MaisonNeue-Bold.woff2';
// @ts-expect-error
import bookWoff2 from '../fonts/MaisonNeue-Book.woff2';
// @ts-expect-error
import bookItalicWoff2 from '../fonts/MaisonNeue-BookItalic.woff2';
// @ts-expect-error
import demiWoff2 from '../fonts/MaisonNeue-Demi.woff2';
// @ts-expect-error
import mediumWoff2 from '../fonts/MaisonNeue-Medium.woff2';
// @ts-expect-error
import monoRegularWoff2 from '../fonts/MaisonNeueMono-Regular.woff2';
/* eslint-enable */
import { focusShadow } from './focus-styling';
import { font200book } from './fonts';

const ua = globalThis.navigator?.userAgent ?? '';
// Doesn't matter if we guess wrong
const isProbablyChromiumOnAndroid =
  ua.includes(' Android ') && ua.includes(' Chrome/');

export const GlobalStyles: React.FC<{
  styles?: ReturnType<typeof css>;
}> = ({ styles }) => (
  <Global
    styles={[
      css`
        @font-face {
          font-family: MaisonNeue;
          src: url('${bookWoff2}') format('woff2');
          font-display: swap;
        }

        @font-face {
          font-family: MaisonNeue;
          font-style: italic;
          src: url('${bookItalicWoff2}') format('woff2');
          font-display: swap;
        }

        @font-face {
          font-family: MaisonNeue;
          src: url('${mediumWoff2}') format('woff2');
          font-weight: 500;
          font-display: swap;
        }

        @font-face {
          font-family: MaisonNeue;
          src: url('${demiWoff2}') format('woff2');
          font-weight: 600;
          font-display: swap;
        }

        @font-face {
          font-family: MaisonNeue;
          src: url('${boldWoff2}') format('woff2');
          font-weight: bold;
          font-display: swap;
        }

        @font-face {
          font-family: MaisonNeue;
          src: url('${blackWoff2}') format('woff2');
          font-weight: 900;
          font-display: swap;
        }

        @font-face {
          font-family: MaisonNeueMono;
          src: url('${monoRegularWoff2}') format('woff2');
          font-display: swap;
        }

        @font-face {
          font-family: fallback;
          src: local(Arial);
          ascent-override: 99.2%;
          descent-override: 33.6%;
        }

        *,
        ::before,
        ::after {
          box-sizing: border-box;
        }

        html,
        body {
          height: 100%;
        }

        html {
          font-family: MaisonNeue, fallback, sans-serif, 'Apple Color Emoji',
            'Segoe UI Emoji';
          ${font200book};
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          font-feature-settings: 'kern', 'liga';
        }

        body {
          margin: 0;
          background: #fff;
          color: ${colors.black};
          /* Avoid page jumping when navigating, depending on the height of the content */
          overflow-y: scroll;
          -webkit-text-size-adjust: 100%;
          touch-action: manipulation;
          caret-color: ${colors.blue};
        }

        /* Only set these on children of body, since the missing border
        on the scrollbar makes the page look misaligned. */
        body *::-webkit-scrollbar {
          background-color: ${colors.background};
        }

        body *::-webkit-scrollbar-thumb {
          border-radius: 10px;
          background-color: ${colors.grayShadowOpaque};
          border: 3px solid ${colors.background};
        }

        body *::-webkit-scrollbar-thumb:hover {
          background-color: ${colors.wcagNonText};
        }

        b,
        strong {
          font-weight: 600;
        }

        p {
          margin: 1em 0;
        }

        label {
          -webkit-tap-highlight-color: transparent;
        }

        .js-focus-visible *:focus:not(.focus-visible) {
          outline: none;
        }

        .js-focus-visible .focus-visible {
          outline: none;
          box-shadow: ${focusShadow};
        }

        [hidden] {
          display: none !important;
        }
      `,
      styles,
      isProbablyChromiumOnAndroid
        ? css`
            /* Work around a huge graphics bug on Chromium/Android where
               parts of the UI gets corrupted. Tested in Edge 121. */
            svg {
              transform: translateZ(0);
            }
          `
        : null,
    ]}
  />
);
