import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { fonts, links, spacing } from 'folio-common-components';
import { colors } from 'folio-design-tokens';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { ContinueLink } from '../../components/ContinueLink';
import { TextButton } from '../../components/buttons';
import { ClipboardTagIcon, MoneyBagDollarIcon, PersonIcon } from '../../icons';
import { pageMap } from '../../paths';
import { useActions, useSelector } from '../../state';

const HomeContainer = styled.div`
  margin: auto;
  ${fonts.font300book};
`;

const Heading = styled.h1`
  font-size: 30px;
  line-height: 1.2;
  font-weight: 900;
  text-indent: -0.04em; /* Optical alignment */
  margin: 0;
  text-wrap: balance;

  a {
    ${links.defaultLink};
    ${links.blackLink};
    font-weight: inherit;
    text-decoration-thickness: 4px;
  }

  @media (min-width: 400px) {
    font-size: 36px;
  }

  @media (min-width: 450px) {
    font-size: 42px;
  }

  @media (min-width: 500px) {
    font-size: 48px;
  }

  @media (min-width: 600px) {
    font-size: 56px;

    a {
      text-decoration-thickness: 6px;
      text-underline-offset: 8px;
    }
  }
`;

const PrereqsList = styled.ul`
  list-style: none;
  padding-left: 2rem;
  margin: 0;
`;

const Section = styled.div`
  ${spacing.getSpacing([16, 16, 32], 'margin-bottom')};
`;

const Box = styled.div`
  border-radius: var(--border-radius);
  border: 2px solid ${colors.blue};
  ${spacing.getSpacing([16, 32], 'padding', 'margin-bottom', 'gap')};
`;

const ListItem: React.FC<
  React.PropsWithChildren & {
    icon: (props: React.SVGProps<SVGSVGElement>) => React.ReactNode;
  }
> = ({ children, icon }) => {
  const IconComponent = icon;
  return (
    <li
      css={css`
        position: relative;
        display: grid;
        grid-template-columns: auto 1fr;
        margin: 0;
        ${spacing.getSpacing([16], 'margin-top')};
      `}
    >
      <span
        css={css`
          margin-left: -2rem;
        `}
      >
        <IconComponent
          css={css`
            display: block;
            height: 24px; /* Same as the text */
          `}
        />
      </span>
      {children}
    </li>
  );
};

const HomePage: React.FC = () => {
  const navigate = useNavigate();
  const pristine = useSelector(e => e.app.pristine);
  const { appThunks } = useActions();
  return (
    <HomeContainer>
      <Heading>
        Den raskeste veien til nytt AS, fra&nbsp;
        <a href="https://folio.no">Folio</a>
      </Heading>

      <Box css={spacing.getSpacing([24, 32], 'margin-vertical')}>
        <p
          css={css`
            margin: 0;
          `}
        >
          Det koster ingenting, utenom 5784&nbsp;kr til
          Brønnøy&shy;sund&shy;registrene. Du&nbsp;trenger:
        </p>
        <PrereqsList role="list">
          <ListItem icon={MoneyBagDollarIcon}>
            30&nbsp;000&nbsp;kr i egenkapital
          </ListItem>
          <ListItem icon={PersonIcon}>Fødselsnummer til de som er med</ListItem>
          <ListItem icon={ClipboardTagIcon}>BankID</ListItem>
        </PrereqsList>
      </Box>

      <Section>
        <ContinueLink to={pageMap.step1}>Sett i gang</ContinueLink>
      </Section>

      {!pristine && (
        <Section
          css={css`
            text-align: center;
          `}
        >
          <TextButton
            level="destructive"
            onClick={() => {
              appThunks.boot();
              navigate({
                pathname: pageMap.step1,
                search: window.location.search,
              });
            }}
          >
            Slett alt og begynn på nytt
          </TextButton>
        </Section>
      )}

      <Section
        css={css`
          text-align: center;
        `}
      >
        <p>
          <b>Ikke helt sikker?</b> Du kan trygt teste ut Stiftemaskinen. Vi spør
          om bekreftelse før selskapet blir registrert.
        </p>
      </Section>
    </HomeContainer>
  );
};

// React.lazy only supports default exports:
// https://reactjs.org/docs/code-splitting.html#named-exports
// eslint-disable-next-line import/no-default-export
export default HomePage;
