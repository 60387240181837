import type * as React from 'react';
export const FingerprintIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={22}
    fill="none"
    viewBox="0 0 22 22"
    aria-hidden="true"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeWidth={2}
      d="M12 20c-3-2-3.5-5.5-3-7.5"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeWidth={2}
      d="M7.4104 20.029C4 15.5 5 11.5693 6.5 9.9994 8 8.4294 10.6753 8.3437 12 10c2 2.5006-1 6.0007 2.5 8 2.5319 1.4463 6-.5 6-4 0-4-.5-8.5003-5.5-11.0007C10.719.8585 5.753.9433 1.542 4.5"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeWidth={2}
      d="M2.5 18c-1.5-2.9996-1-6.4996 0-8.4996 1-2 4.0097-5.037 8.5-4.0006C17.5 7.0001 16.5 14.5 16.5 14.5"
    />
  </svg>
);
