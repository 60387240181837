import * as React from 'react';

export interface Props {
  width?: string | number;
  height?: string | number;
  className?: string;
  color?: string;
  stroke?: string;
}

export const Logo: React.FC<Props> = props => {
  const { width = 30, height = 36, className, color, stroke = '#000' } = props;

  return (
    <svg
      viewBox="0 0 30 36"
      width={width}
      height={height}
      className={className}
      role="img"
      aria-label="Folio"
    >
      <g
        stroke={stroke}
        strokeLinecap="square"
        strokeMiterlimit="10"
        strokeWidth="2"
      >
        <path fill={color ? color : '#fff'} d="M20,10V34H2V6A4,4,0,0,1,6,2" />
        <path fill={color ? color : '#fff'} d="M2,20a4,4,0,0,1,4-4H6" />
        <path
          fill={color ? color : '#bdb2f5'}
          d="M28,10H10V6A4,4,0,0,0,6,2H24a4,4,0,0,1,4,4Z"
        />
        <path
          fill={color ? color : '#fdc7bd'}
          d="M28,24H10V20a4,4,0,0,0-4-4H24a4,4,0,0,1,4,4Z"
        />
      </g>
    </svg>
  );
};
